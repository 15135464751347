@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: 400
  src: url("./MaterialIcons-Regular.ttf") format("truetype")
  font-display: swap

html
  margin: 0
  padding: 0

body
  padding: 8px
  margin: 8px
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background: url('../images/nwd.jpg') no-repeat center center fixed
  background-size: cover

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

h1, h2, h3
  margin-top: 0

/* Table styles */
table
  width: 100%
  border-collapse: collapse

/* Table header styles */
thead
  background-color: #f1f1f1

th
  font-weight: bold
  padding: 10px
  text-align: left
  border-bottom: 2px solid #ccc

/* Table row styles */
tr:nth-child(even)
  background-color: #f9f9f9

/* Table cell styles */
td
  padding: 10px
  border-bottom: 1px solid #ccc

/* Hover effect */
tr:hover
  background-color: #eaeaea

// ___________________________________ PLUG-INS (e.g. MATERIAL UI) ___________________________________

.material-icons
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: 24px
  display: inline-block
  line-height: 1
  text-transform: none
  letter-spacing: normal
  word-wrap: normal
  white-space: nowrap
  vertical-align: top
  direction: ltr

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale

  /* Support for IE. */
  font-feature-settings: 'liga'
